// Here you can add other styles
.switch {
  position: relative;
  display: inline-block;
  width: 3.8rem;
  height: 2rem;
}

/* make the customizations */
$theme-colors: (
    "primary": #4c9d4a,
);
/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

.btn-primary{
  color:white;
  &:hover{
    color:white;
  }
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

 
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $success;
}

input:focus + .slider {
  box-shadow: 0 0 1px $success;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.c-sidebar .c-sidebar-brand {
  background-color: white;
}

.c-sidebar {
  background-color: white;
  color: blue;
}

.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
  // color: #4c9d4a;
  color: #5b5d69;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  //background-color: #4c9d4a;
  background-color: #5b5d69;
}

// status dropdown option select color
.optionSelect{
  border: 1px solid #e6e0da;
}
.modal-dialog-scrollable{
  .btn-close{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .modal-header{
    padding-top:30px;
    padding-bottom: 5px;
  }
}
.custom-modal{
  .bid-amout-user{
    background-color: lightgray;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .box-bid{
    background-color: #f2f2f2;
  }
  
}
.btn-blue{
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-danger{
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-blue{
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
 